@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Public Sans", sans-serif;
}

// Buttons
.btn,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-2.5 py-2;
}

.btn-sm {
  @apply px-2 py-1;
}

.btn-xs {
  @apply px-2 py-0.5;
}

.overflow-x-auto::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.overflow-x-auto::-webkit-scrollbar {
  height: 8px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px;
  background-color: #f5f5f5;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #4db752;
  border-radius: 25px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  list-style: none;
  outline: none;
  margin-bottom: 2rem;
}
.pagination > .active > a {
  background-color: #4db752;
  border-color: #4db752;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #4db752;
  padding: 5px 10px;

  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #4db752;
  border-color: #4db752;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #4db752;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.pagination .previous.disabled,
.pagination .next.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.pagination .previous,
.pagination .next {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .previous a,
.pagination .next a {
  border: none;
}

.pagination li a {
  border-radius: 6px;
  margin-right: 0.5rem;
}

.hide-popup {
  display: none;
}

.animation-slideup {
  animation-name: slideUp;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}
.animation-slidedown {
  animation-name: slidedown;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}

@keyframes slideUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  // 50% {
  //   transform: translateY(100%);
  //   opacity: 0;
  // }

  // 60%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slidedown {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media screen and (max-width: 370) {
  .hide-icon {
    display: none;
  }
}

.ag-format-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;

  position: relative;
}

.ag-timeline-block {
  padding: 10px 0;
  background-color: rgba(115, 102, 255, 0.12);
}
.ag-timeline_title-box {
  padding: 0 0 30px;

  text-align: center;
}
.ag-timeline_tagline {
  font-size: 40px;
  color: rgb(84, 89, 95);
}
.ag-timeline_title {
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  font-size: 25px;
  margin-top: 1rem;
}

.ag-timeline_title-para {
  font-size: 18px;
  color: var(--bs-primary);
  font-family: var(--bs-body-font-family);
  width: 60%;
  margin: auto;
}

.ag-timeline_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  margin: 0 0 130px 0;

  position: relative;
}
.ag-timeline_item:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.ag-timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  position: relative;
}
.ag-timeline_line {
  width: 8px;
  background: var(--bs-primary);

  position: absolute;
  top: 0;
  left: 50%;
  bottom: 200px;

  overflow: hidden;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ag-timeline_line-progress {
  width: 100%;
  height: 20%;
  background: linear-gradient(
    90deg,
    rgba(41, 104, 178, 1) 0%,
    rgba(72, 102, 176, 1) 35%,
    rgba(171, 49, 116, 1) 100%
  );
  opacity: 0;
}
.ag-timeline-card_point-box {
  margin-left: 20px;
  margin-right: 20px;
}
.ag-timeline-card_point {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  line-height: 30px;
  width: 230px;
  /* border: 3px solid var(--bs-primary); */
  /* background: rgb(41, 104, 178); */
  background: linear-gradient(
    90deg,
    rgba(41, 104, 178, 1) 0%,
    rgba(72, 102, 176, 1) 35%,
    rgba(171, 49, 116, 1) 100%
  );

  text-align: center;
  font-family: "ESL Legend", sans-serif;
  font-size: 20px;
  color: #fff;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.js-ag-active .ag-timeline-card_point {
  color: #1d1d1b;
  background-color: var(--bs-primary);
}

.ag-timeline-card_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  height: 100%;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;

  background-color: #fff;

  opacity: 1;

  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  -o-box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);

  animation-fill-mode: both;

  position: relative;
}
.js-ag-animated .ag-timeline-card_item {
  opacity: 0;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.js-ag-animated.js-ag-show:nth-child(2n + 1) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-left;
  -moz-animation-name: card-animation-left;
  -o-animation-name: card-animation-left;
  animation-name: card-animation-left;
}
.js-ag-animated.js-ag-show:nth-child(2n) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-right;
  -moz-animation-name: card-animation-right;
  -o-animation-name: card-animation-right;
  animation-name: card-animation-right;
}

.ag-timeline-card_arrow {
  height: 18px;
  width: 18px;
  margin-top: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ag-timeline-card_arrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}
.ag-timeline-card_arrow {
  margin-left: -10px;

  right: auto;
  left: 0;
}

.ag-timeline-card_img {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  object-fit: cover;
}

.ag-timeline-card_img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ag-timeline-card_meta-box {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_meta-box {
  text-align: right;
}
.ag-timeline-card_meta {
  margin: 10px 0 0;
  font-size: 20px;
  font-weight: 700;
  color: var(--bs-primary);
}
.ag-timeline-card_info {
  padding: 20px 30px;
}
.ag-timeline-card_title {
  display: none;
  margin: 10px 0 0;
  font-weight: bold;
  font-size: 28px;
  color: var(--bs-primary);
}
.ag-timeline-card_desc {
  line-height: 1.45;

  font-size: 16px;
  color: var(--bs-primary);
}

@-webkit-keyframes card-animation-left {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes card-animation-left {
  0% {
    opacity: 0;

    -moz-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes card-animation-left {
  0% {
    opacity: 0;

    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes card-animation-left {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
    -moz-transform: translateX(-400px);
    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes card-animation-right {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes card-animation-right {
  0% {
    opacity: 0;

    -moz-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes card-animation-right {
  0% {
    opacity: 0;

    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes card-animation-right {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
    -moz-transform: translateX(400px);
    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.ag-timeline_line {
  top: 20px !important;
  left: 100px;
  bottom: 270px;
}
.ag-timeline-card_point {
  height: 200px;
  line-height: 40px;
  width: 200px;
}

.ag-timeline-card_img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.ag-timeline_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 60px 0;
  position: relative;
}
.ag-timeline-card_title {
  display: block;
}

.ag-timeline-card_meta-box {
  display: none;
}
.ag-timeline-card_point-box {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;

  margin-left: 8px;
}

.ag-timeline-card_item {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;

  max-width: 100%;
  margin-top: 220px;
  margin-left: -100px;
  margin-right: 1rem;
}
.ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.js-ag-animated.js-ag-show:nth-child(2n) .ag-timeline-card_item,
.js-ag-animated.js-ag-show:nth-child(2n + 1) .ag-timeline-card_item {
  -webkit-animation-name: card-animation-right;
  -moz-animation-name: card-animation-right;
  -o-animation-name: card-animation-right;
  animation-name: card-animation-right;
}
.ag-timeline-card_arrow {
  margin-left: -10px;
  right: auto;
  left: 20px;
  top: -30px;
}
.ag-timeline-card_arrow {
  margin-left: -10px;
  right: auto;
  left: 20px;
  top: -30px;
}
